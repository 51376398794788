// Create your own language definition here
// You can safely look at other samples without losing modifications.
// Modifications are not saved on browser refresh/close though -- copy often!
const language = {
  // Set defaultToken to invalid to see what you do not tokenize yet
  // defaultToken: 'invalid',

  keywords: [
    /* Logic */ 'if', 'and', 'or', 'xor', 'not', 'switch',
    /* Numeric */ 'min', 'max', 'sum', 'avg', 'count', 'round', 'rounddown', 'roundup', 'abs', 'intercept', 'sqrt', 'sin', 'cos', 'tan',
    /* String */ 'left', 'right', 'mid', 'len', 'find', 'substitute', 'concat', 'contains',
    /* Collection */ 'map', 'filter', 'all', 'any', 'pluck',
    'case', 'when', 'then', 'else', 'end',
   ],

  operators: [
    /* Comparison */ '>', '<', '>=', '<=', '=', '!=',
    /* Math */ '+', '-', '*', '/', '%', '^', '|', '&', '<<', '>>',
  ],

  brackets: [
		{ open: '(', close: ')', token: 'delimiter.parenthesis' },
		{ open: '{', close: '}', token: 'delimiter.curly' },
		{ open: '[', close: ']', token: 'delimiter.square' }
	],

  // we include these common regular expressions
  symbols: /([\.]{2})|([=><!:\|\+\-\*\/%,;]+)/,
  // symbols:  /[=><!~?:&|+\-*\/\^%]+/,

  // C# style strings
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  // The main tokenizer for our languages
  ignoreCase:true,
  tokenizer: {
    root: [
      // identifiers and keywords
      [/[a-z_$][\w$]*/, { cases: { '@keywords': 'function',
                                   '@default': 'variable' } }],

      // whitespace
      { include: '@whitespace' },

      // delimiters and operators
			[/[{}()\[\]]/, '@brackets'],
			[/@symbols/, {
				cases: {
					'@keywords': 'function',
          ',': 'delimiter.comma',
					'@operators': 'operator',
					'@default': ''
				}
			}],

      // numbers
      [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
      [/\d+/, 'number'],

      // strings
			[/"/, { token: 'string.d.delim', next: '@dstring.d."' }],
			[/'/, { token: 'string.sq.delim', next: '@sstring.sq' }],

      // characters
      [/'[^\\']'/, 'string'],
      [/(')(@escapes)(')/, ['string','string.escape','string']],
      [/'/, 'string.invalid']
    ],

    comment: [
      [/[^\/*]+/, 'comment' ],
      [/\/\*/,    'comment', '@push' ],    // nested comment
      ["\\*/",    'comment', '@pop'  ],
      [/[\/*]/,   'comment' ]
    ],

    // single quote strings (also used for symbols)
		// sstring.<kind>  where kind is 'sq' (single quote) or 's' (symbol)
		sstring: [
			[/[^\\']+/, 'string.$S2'],
			[/\\\\|\\'|\\$/, 'string.$S2.escape'],
			[/\\./, 'string.$S2.invalid'],
			[/'/, { token: 'string.$S2.delim', next: '@pop' }]
		],

		// double quoted "string".
		// dstring.<kind>.<delim> where kind is 'd' (double quoted), 'x' (command), or 's' (symbol)
		// and delim is the ending delimiter (" or `)
		dstring: [
			[/[^\\`"#]+/, 'string.$S2'],
			[/\\$/, 'string.$S2.escape'],
			[/@escapes/, 'string.$S2.escape'],
			[/\\./, 'string.$S2.escape.invalid'],
			[/[`"]/, {
				cases: {
					'$#==$S3': { token: 'string.$S2.delim', next: '@pop' },
					'@default': 'string.$S2'
				}
			}]
		],

    whitespace: [
      [/[ \t\r\n]+/, 'white'],
      [/\/\*/,       'comment', '@comment' ],
      [/\/\/.*$/,    'comment'],
    ],
  },
}

const config = {
  comments: {
		lineComment: '//',
		blockComment: ['/*', '*/']
	},
	brackets: [
		['(', ')'],
		['{', '}'],
		['[', ']']
	],
	autoClosingPairs: [
		{ open: '{', close: '}' },
		{ open: '[', close: ']' },
		{ open: '(', close: ')' },
		{ open: '"', close: '"' },
		{ open: "'", close: "'" }
	],
	surroundingPairs: [
		{ open: '{', close: '}' },
		{ open: '[', close: ']' },
		{ open: '(', close: ')' },
		{ open: '"', close: '"' },
		{ open: "'", close: "'" }
	],
}

const theme = {
  base: 'vs',
  inherit: true,
  rules: [
    { token: 'variable', foreground: '521ec2', fontStyle: 'bold' },
    { token: 'string', foreground: 'c62828', fontStyle: 'italic' },
    { token: 'function', foreground: '1e42c2', fontStyle: 'italic' },
    { token: 'operator', foreground: 'dd7700' },
    { token: 'number', foreground: '009f65', fontStyle: 'bold' },
    { token: 'delimiter', foreground: 'c94bb8', fontStyle: 'bold' },
    { token: 'comment', foreground: '878787', fontStyle: 'italic' },
  ],
  colors: {
    'editor.foreground': '222222'
  }
}

export { language, config, theme }
